<template>
  <page-header-wrapper>
    <div class="a-card">
      <SiteSearch
        @resetData="resetSearchForm"
        :queryParam="queryParam"
        @handleAdd="handleAdd"
      />
      <div class="table-operator">
        <a-button type="dashed" @click="tableOption">{{ (optionAlertShow && '关闭') || '开启' }} alert</a-button>
        <!-- <a-dropdown v-if="selectedRowKeys.length > 0">
          <a href="#">操作</a>
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" @click="handleDelete()">
              <a-icon type="delete"/>
              删除
            </a-menu-item>
            <a-menu-item class="hide" key="2">
              <a-icon type="down"/>
              批量操作
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :columns="columns"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        rowKey="siteId"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)" vv1-if="$auth('table.site.edit')">编辑</a>
          <a-dropdown>
            <a class="ant-dropdown-link"> 更多 <a-icon type="down"/> </a>
            <a-menu slot="overlay">
              <a-menu-item v-if="record.status === 1">
                <a @click="handleDisable(record)">{{ $t('button.disable') }}</a>
              </a-menu-item>
              <a-menu-item v-if="record.status === 0">
                <a @click="handleEnable(record)">{{ $t('button.enable') }}</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
        <div slot="creatorInfo" slot-scope="text, record">
          {{ record.creatorName }}<br/>
          {{ formatDateTime(record.createTime) }}
        </div>
        <div slot="updaterInfo" slot-scope="text, record">
          {{ record.updaterName }}<br/>
          {{ formatDateTime(record.updateTime) }}
        </div>
        <div slot="marketplaceCode" slot-scope="text, record">
          {{ record.country }}- {{ record.marketplaceCode }}-
          {{ record.marketplaceName }}
        </div>
      </s-table>
    </div>
    <SiteEdit ref="siteEdit"/>
    <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
  </page-header-wrapper></template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import { deleteSite, disableSite, enableSite, getSiteData, searchSite } from '@/api/system/site'
import { STable } from '@/components'

import SiteSearch from '@/views/system/SiteSearch'
import SiteEdit from '@/views/system/components/SiteEdit'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'SiteList',
  components: {
    CommentEditor,
    STable,
    SiteEdit,
    SiteSearch
  },
  data () {
    return {
      confirmEditLoading: false,
      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
      columns: [
        {
          defaultTitle: '操作',
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          },
          width: 150
        },
        {
          defaultTitle: '国家',
          title: this.$t('columnName.country'),
          dataIndex: 'country',
          key: 'country',
          align: 'left'
        },
        {
          defaultTitle: '市场名称',
          title: this.$t('columnName.marketplaceCode'),
          dataIndex: 'marketplaceCode',
          key: 'marketplaceCode',
          align: 'left',
          scopedSlots: {
            customRender: 'marketplaceCode'
          }
        },
        {
          defaultTitle: '站点编号',
          title: this.$t('columnName.siteCode'),
          dataIndex: 'siteCode',
          key: 'siteCode',
          align: 'left'
        },
        {
          defaultTitle: '站点名称',
          title: this.$t('columnName.siteName'),
          dataIndex: 'siteName',
          key: 'siteName',
          align: 'left'
        },

        {
          defaultTitle: '状态',
          title: this.$t('columnName.status'),
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          customRender: (text) => this.getCode('enableStatus', text)
        },
        {
          defaultTitle: '创建人',
          title: this.$t('columnName.creatorName'),
          dataIndex: 'creatorName',
          key: 'creatorName',
          align: 'left',
          ellipsis: true,
          scopedSlots: {
            customRender: 'creatorInfo'
          }
        },
        {
          defaultTitle: '更新人',
          title: this.$t('columnName.updaterName'),
          dataIndex: 'updaterName',
          key: 'updaterName',
          align: 'left',
          ellipsis: true,
          scopedSlots: {
            customRender: 'updaterInfo'
          }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getSite(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      isAdd: null // 是否是新增 ture-新增，false-修改
    }
  },
  created () {
    this.tableOption()
  },
  mounted () {
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchSite(Object.assign(parameter, this.queryParam)).then((res) => {
        console.log('loadData->getSiteList.parameter', parameter, res)
        return res
      })
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd (record) {
      this.$refs['siteEdit'].add()
    },

    handleEdit (record) {
      const that = this
      getSiteData({ siteId: record.siteId })
        .then((res) => {
          if (!res.countryList) {
            res.countryList = []
          }
          this.$refs['siteEdit'].edit(res)
        })
        .catch((res) => {
          that.$message.success(this.$t('get.entity.site.fail'))
        })
      // 弹出编辑页面
      this.siteModalShow = true
    },

    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableSite(record)
          .then((res) => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableSite(record)
          .then((res) => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable (record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable (record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm () {
      this.queryParam = {}
      this.$refs.table.refresh(true)
    },
    handleDelete () {
      const that = this
      const params = {
        siteId: this.selectedRows[0].siteId
      }
      deleteSite(params)
        .then((res) => {
          that.$message.success(this.$t('save.entity.afterSaleService.success'))
          this.$refs.table.refresh(true)
        })
        .catch((res) => {
          that.$message.success(this.$t('save.entity.afterSaleService.fail'))
        })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
