<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="8" :sm="24">
            <a-form-item
              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
            >
              <SelectMarketplace
                :data="queryParam"/>
            </a-form-item>
          </a-col>
          <!--          <a-col :md="8" :sm="24">-->
          <!--            <a-form-item-->
          <!--              :label="$t('columnName.country')"-->
          <!--              :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
          <!--              :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
          <!--            >-->
          <!--              <a-input-->
          <!--                v-model="queryParam.country"-->
          <!--                v-decorator="[-->
          <!--                  'queryParam.supplierCompanyName',-->
          <!--                  {-->
          <!--                    rules: [-->
          <!--                      { required: true, message: '请输入' + this.$t('columnName.country') },-->
          <!--                      { max: 100, message: this.$t('columnName.country') + '长度小于等于100' },-->
          <!--                    ],-->
          <!--                  },-->
          <!--                ]"-->
          <!--                :placeholder="'请输入' + this.$t('columnName.country')"-->
          <!--                name="queryParam.country"-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :md=" 24" :sm="24">
            <span
              :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
              class="table-page-search-submitButtons"
            >
              <a-button icon="plus" type="primary" @click="$emit('handleAdd')">{{ $t('button.create') }}</a-button>
              <a-divider type="vertical"/>
              <a-button icon="search" type="primary" @click="$parent.$parent.$refs.table.refresh(true)">{{ $t('button.search')
              }}</a-button><a-divider type="vertical"/>
              <a-button style="margin-left: 8px" @click="resetData">{{ $t('button.reset') }}</a-button>
              <!-- <a style="margin-left: 8px" @click="toggleAdvancedSearch">
                {{ showAdvancedSearchCondition ? $t('button.collapseIn') : $t('button.collapseOut') }}
                <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'"/>
              </a> -->
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>
<script>

import { listMarketplace } from '@/api/system/marketplace'
import SelectMarketplace from '@/views/component/SelectMarketplace'
export default {
  name: 'SiteSearch',
  components: { SelectMarketplace },
  props: {
    // 查询参数
    queryParam: {
      type: Object
    },
    showAdvancedSearchCondition: {
      type: Boolean
    }
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    listMarketplace({}).then(res => {
      this.codeList.marketplaceCode = res.data
    })
  },
  methods: {
    toggleAdvancedSearch () {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetData () {
      this.$emit('resetData')
    }
  }
}
</script>
