<template>
  <a-modal
    width="50%"
    :title="$t('page.site')"
    :visible="siteModalShow"
    @ok="handleEditOk"
    @cancel="handleEditCancel"
  >
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <a-form
        :form="form"
        :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
        :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
      >
        <SelectMarketplace
          v-if="!siteData.siteId"
          :data="siteData"/>
        <a-form-item
          :label="$t('field.siteCode')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'siteCode',
              {
                rules: $rules([
                  { required: true, message: '请输入' + this.$t('field.siteCode') },
                  { max: 100, message: this.$t('field.siteCode') + '长度小于等于100' },
                  { type:'checkExists', existIdValue:siteData.siteId, checkField:'siteCode', idFieldName: 'siteId',tableName:'site' }
                ]),
              },
            ]"
            v-model="siteData.siteCode"
            name="siteData.siteCode"
            :placeholder="'请输入' + this.$t('field.siteCode')"
            :maxLength="4"
          />
        </a-form-item>
        <a-form-item
          :label="$t('field.siteName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'siteName',
              {
                initialValue: siteData.siteName,
                rules: [
                  { required: true, message: '请输入' + this.$t('field.siteName') },
                  { max: 100, message: this.$t('field.siteName') + '长度小于等于100' },
                ],
              },
            ]"
            v-model="siteData.siteName"
            name="siteData.siteName"
            :placeholder="'请输入' + this.$t('field.siteName')"
          />
        </a-form-item>
      </a-form>
    </a-card>
  </a-modal>
</template>

<script>
import { saveSiteData } from '@/api/system/site'
import SelectMarketplace from '@/views/component/SelectMarketplace'

export default {
  name: 'EditSite',
  components: { SelectMarketplace },
  props: {},
  data () {
    return {
      form: this.$form.createForm(this),
      siteModalShow: false,
      siteData: {},
      codeList: {
        site: []
      }
    }
  },
  methods: {
    createForm () {
      let that = this
      this.$nextTick(() => {
        const formData = {}
        Object.keys(that.form.getFieldsValue()).map(key => (formData[key] = this.siteData[key]))
        that.form.setFieldsValue(formData)
        console.log('初始化form', that.form)
      })
    },
    add () {
      this.siteData = { countryList: [] }
      this.createForm()
      this.siteModalShow = true
    },
    edit (data) {
      this.siteData = data
      this.createForm()
      // 弹出编辑页面
      this.siteModalShow = true
    },
    onsiteChanged (value, option) {
      this.siteData.siteCode = value
      this.siteData.siteName = option.data.attrs.siteName
      this.siteData.siteId = option.data.attrs.siteId
      this.siteData.country = option.data.attrs.country
    },
    handleEditCancel () {
      // 弹出编辑页面
      this.siteModalShow = false
    },
    handleEditOk () {
      const that = this
      this.form.validateFieldsAndScroll((err, values) => {
        console.log('validateFieldsAndScroll', err, values)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        Object.assign({ ...that.marketplaceData, ...that.form.getFieldsValue() })
        saveSiteData(this.siteData)
          .then((res) => {
            this.siteData = {}
            // 弹出编辑页面
            this.siteModalShow = false
            that.$message.success(this.$t('save.entity.site.success'))
            that.$parent.$parent.$refs.table.refresh(true)
          })
          .catch((res) => {
            that.$message.success(this.$t('save.entity.site.fail'))
          })
      })
    }
  },
  mounted () {
    console.log('mounted')
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
